





























import { Vue, Component, VModel, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class ContentDialog extends Vue {
  @VModel()
  dialog!: boolean;

  @Prop()
  readonly title!: string;

  @Prop({ default: '100%' })
  readonly width!: string;

  @Prop({ default: '100%' })
  readonly maxWidth!: string;

  @Prop()
  readonly dense!: boolean;

  @Prop()
  readonly hideCloseButton!: boolean;

  @Prop()
  readonly customClass!: string;

  @Prop({ default: '' })
  readonly customPadding!: string;

  @Emit()
  afterClose(): boolean {
    return this.dialog;
  }

  @Emit('close')
  close(): void {
    this.dialog = false;
  }

  get cardClass(): string {
    if (this.customClass) {
      return this.customClass;
    }

    return this.dense ? 'pa-1' : 'pa-4';
  }

  get customStyle(): string {
    if (this.customPadding !== '') {
      return `padding: ${this.customPadding};"`;
    }

    return '';
  }
}
