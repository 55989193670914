













































































































































































































































































































































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import { padStart, pick, random, sortBy } from 'lodash';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ClientStatusEnum } from '@/enums/crm/client-status.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import { DefaultExtensions } from '@/utils/tiptap-extensions/default-extensions';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Tooltip from '@/components/tooltip.vue';
import Field from '@/components/field.vue';
import DatePickerField from '@/components/date-picker-field.vue';
import ActivityService from '@/services/crm/activity.service';
import TimePickerField from '@/components/time-picker-field.vue';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import SettingsModel from '@/models/crm/settings.model';
import ActivityEventModel from '@/models/crm/activity-event.model';
import { DateHelper } from '@/utils/helpers/date-helper';
import RepresentativeModel from '@/models/crm/representative.model';
import RepresentativeService from '@/services/crm/representative.service';
import ClientModel from '@/models/crm/client.model';
import dayjs from '@/plugins/dayjs';
import OriginModel from '@/models/crm/origin.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import GroupModel from '@/models/crm/group.model';
import WysiwygEditor from '@/components/wysiwyg-editor.vue';
import UserModel from '@/models/user.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import { ObjectHelper } from '@/utils/helpers/object-helper';
import { toIsoDateString } from '@/utils/formatters/to-iso-date-string';
import SettingsService from '@/services/crm/settings.service';
import UploadService from '@/services/crm/upload.service';
import { IUploadedFile } from '@/interfaces/uploaded-file.interface';
import DragDropUpload from '@/components/drag-drop-upload.vue';
import CrmClientSearchField from '@/components/crm/client-search-field.vue';
import ActivityEventAttachmentModel from '@/models/crm/activity-event-attachment.model';
import { ActivityEventStatusEnum } from '@/enums/crm/activity-event-status.enum';
import { IKeyValue } from '@/interfaces/key-value.interface';
import ClonedUploadModel from '@/models/crm/cloned-upload.model';
import ProcessModel from '@/models/crm/process.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ProspectModel from '@/models/crm/prospect.model';
import ClientService from '@/services/crm/client.service';
import ProspectService from '@/services/crm/prospect.service';
import ConfirmationDialog from '../confirmation-dialog.vue';
import { IValidationRules } from '@/interfaces/validation-rules.interface';
import SessionService from '@/services/session.service';
import ContentDialog from '@/components/content-dialog.vue';

@Component({
  components: {
    ContentDialog,
    Tooltip,
    Field,
    DatePickerField,
    TimePickerField,
    WysiwygEditor,
    DragDropUpload,
    CrmClientSearchField,
    ConfirmationDialog,
  },
})
export default class CrmActivityCalendarEventForm extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmRepresentativeService)
  private representativeService!: RepresentativeService;

  @inject(InjectionIdEnum.CrmUploadService)
  protected uploadService!: UploadService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @Prop()
  id!: number;

  @Prop()
  cloneId!: number;

  @Prop()
  defaultClient!: ClientModel;

  @Prop()
  defaultClientType!: ClientTypeEnum;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Prop()
  startDate!: Date;

  @Prop()
  endDate!: Date;

  @Prop()
  timed!: boolean;

  @Prop()
  process!: ProcessModel;

  @Prop({ default: false })
  isRescheduling!: boolean;

  @Prop({ default: false })
  accessingFromKanban!: boolean;

  @Prop({ default: false })
  blockSaving!: boolean;

  @Prop()
  disableAutoComplete!: boolean;

  formIsValid = true;

  model: ActivityEventModel = plainToClass(ActivityEventModel, {
    id: null,
    cnpj: null,
    prospect: null,
    nome: null,
    representante: null,
    titulo: null,
    descricao: null,
    dataHoraInicio: null,
    dataHoraFim: null,
    atendente: null,
    tipoHistorico: null,
    origem: null,
    anexos: [],
    processo: this.process,
    idRetorno: null,
    diaInteiro: null,
    flagEnviarEmail: 0,
  });

  rules: IValidationRules = {
    titulo: [ValidationRules.required, [ValidationRules.maxLength, 255]],
    dataHoraInicio: [ValidationRules.required],
    dataHoraFim: [ValidationRules.required],
    atendente: [ValidationRules.required],
    tipoHistorico: [ValidationRules.required],
    grupo: [ValidationRules.required],
    origem: [ValidationRules.required],
  };

  dialogConfig: IKeyValue<IDialogConfig> = {
    rescheduleConfirmation: {
      message: '',
      color: '',
      show: false,
      onChoice: () => {},
    },
    sendEventEmail: {
      show: false,
      event: null,
      client: null,
    },
  };

  dialogClient: IKeyValue<IDialogConfig> = {
    rescheduleConfirmation: {
      show: false,
      onChoice: () => {
      },
    },
  };

  get urlFrame(): string {
    let id = '';

    if (this.clientType === ClientTypeEnum.Client) {
      id = this.client == null ? '' : this.client.cnpjCpf;
    } else {
      id = this.client == null || this.client.prospectId == null ? '' : this.client.prospectId.toString();
    }

    let path = `/crm/dashboard/${id}?token=${this.getApiToken()}`;
    path += `&apiUrl=${this.getApiPath()}`;
    path += `&clientType=${this.clientType}`;
    return path;
  }

  horarioInicial: string | null = null;

  horarioFinal: string | null = null;

  clientItems: ClientModel[] = [];

  representativeOptions: RepresentativeModel[] = [];

  originOptions: OriginModel[] = [];

  historyTypeOptions: HistoryTypeModel[] = [];

  groupOptions: GroupModel[] = [];

  attendantOptions: UserModel[] = [];

  group: GroupModel | null = null;

  loadingHistoryTypeOptions = false;

  showAdditionalFields = false;

  showInternalObservation = false;

  eventClosed = false;

  showUploader = false;

  dayToggle = false;

  uploadEndpoint = '';

  uploadHash = CrmActivityCalendarEventForm.getHash();

  uploadPlace = 'events';

  searchingClient = false;

  searchClient = '';

  clientSearchKey = 0;

  private settings!: SettingsModel;

  private enabledSearchingClient = false;

  private client: ClientModel | null = null;

  private clientType: ClientTypeEnum = ClientTypeEnum.Client;

  private readonly debounce = 450;

  private debounceId!: number;

  private extensions = DefaultExtensions.getExtensionsNoTable();

  @Emit('cancel')
  onCancel(): void {
    // Before go away, delete non uploaded files
    this.model.anexos.filter((file) => !file.id).forEach((file) => this.uploadService.delete(file.path));

    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  @Watch('model.tipoHistorico')
  watchHistoryType(value: HistoryTypeModel): void {
    if (this.id === null && value.textoPadraoEvento) {
      this.showAdditionalFields = true;
      this.model.descricao = value.textoPadraoEvento;
    }
  }

  @Watch('searchClient')
  watchSearchClient(value: string): void {
    clearTimeout(this.debounceId);

    if (!this.enabledSearchingClient || !value || !value.length) {
      return;
    }

    if (value.length < 2) {
      this.clientItems = [];
      this.searchingClient = false;
      return;
    }

    this.searchingClient = true;
    this.debounceId = setTimeout(async () => {
      try {
        if (this.clientType === ClientTypeEnum.Client) {
          const result = await this.clientService.quickSearch(value, ClientTypeEnum.Client);
          this.clientItems = result.map((item) => {
            const newItem = item;
            newItem.nomeFantasia = newItem.nomeFantasia || newItem.nome;
            return newItem;
          });
        } else {
          const result = await this.prospectService.quickSearch(value);
          this.clientItems = result
            .filter((item) => !item.flagExcluido)
            .map((item) => {
              const newItem = item;
              newItem.fantasia = newItem.fantasia || newItem.razaoSocial || newItem.contato;
              return newItem.clientModel;
            });
        }
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.searchingClient = false;
      }
    }, this.debounce);
  }

  goToClientDialog(): void {
    this.dialogClient.rescheduleConfirmation.show = true;
  }

  getApiToken(): string {
    const { apiToken } = this.sessionService;

    if (!apiToken) {
      throw new Error('Api token not defined');
    }

    return apiToken;
  }

  getApiPath(): string {
    const { apiUrl } = this.sessionService;
    if (!apiUrl) {
      throw new Error('Api url not defined');
    }
    return apiUrl;
  }

  async mounted(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
    if (!this.settings.flagRemoveObrigatorioRepresentanteAgenda360) {
      this.rules.representante = [ValidationRules.required];
    }
    this.uploadEndpoint = this.uploadService.getUploadUrl();

    const loader = this.setBusyLoader();
    try {
      const getOptionsTask = this.loadOptions();
      const getEventTask = this.loadModel();
      await Promise.all([getOptionsTask, getEventTask]);

      this.dayToggle = this.model.diaInteiro === 1 && !this.dayToggle;

      if (this.dayToggle) {
        Object.assign(this.rules, {
          dataHoraInicio: null,
          dataHoraFim: null,
        });
      }

      // If a default client type is provided
      if (this.defaultClientType) {
        this.clientType = this.defaultClientType;

        // Otherwise get client type from loaded event
      } else if (this.model?.tipo) {
        this.clientType = this.model.tipo;
      }

      // if default client is provided
      if (this.defaultClient) {
        this.client = this.defaultClient;
        // Otherwise get client from loaded event
      } else if (this.clientType && (this.id || this.cloneId)) {
        if (this.isProspectType) {
          this.client = this.model.prospect?.clientModel as ClientModel;
        } else {
          this.client = await this.clientService.getSummary(this.model?.cnpj as string, this.clientType);
        }
      }

      if (this.client) {
        this.clientItems = [this.client];
        this.model.cnpj = this.client.cnpjCpf;
        this.model.nome = this.client.nome;
        this.clientSearchKey += 1;
      }

      if (this.isProspectType) {
        const prospect = new ProspectModel();
        prospect.codProspect = this.client?.codCliente as string;
        this.model.prospect = prospect;
      }

      // Set default values when creating new
      if (!this.id && !this.cloneId) {
        this.model.dataHoraInicio = this.startDate;
        this.model.dataHoraFim = this.endDate;

        if (this.timed) {
          let startDate = dayjs(this.startDate);
          const endDate = dayjs(this.endDate);

          if (startDate.isSame(endDate)) {
            startDate = startDate.startOf('hour');

            this.horarioInicial = startDate.format('HH:mm');
            this.horarioFinal = startDate.add(0.5, 'hour').format('HH:mm');
          } else {
            this.horarioInicial = startDate.format('HH:mm');
            this.horarioFinal = endDate.format('HH:mm');
          }
        }

        if (this.client?.codRepres) {
          this.model.representante = this.representativeOptions
            .find((x) => (x.chaveIntegracao
              ? x.chaveIntegracao.toUpperCase() === this.client?.codRepres?.toUpperCase()
              : x.codigo.toUpperCase() === this.client?.codRepres?.toUpperCase()));
        }

        this.model.atendente = this.attendantOptions.find((x) => x.id === this.userContactInfo.id);

        // otherwise handle data to edition
      } else {
        const startDate = dayjs(this.model.dataHoraInicio);
        const endDate = dayjs(this.model.dataHoraFim);

        if (this.model.timed) {
          this.horarioInicial = startDate.format('HH:mm');
          this.horarioFinal = endDate.format('HH:mm');
        }

        const initialGroup = this.model.tipoHistorico.grupoArea?.id;
        this.loadHistoryTypes(initialGroup);
        this.group = this.groupOptions.find((x) => x.id === initialGroup) || null;
      }

      this.eventClosed = this.model.situacao === ActivityEventStatusEnum.Closed;
      this.showAdditionalFields = !!this.model.descricao || !!this.model.anexos?.length;

      setTimeout(() => {
        this.enabledSearchingClient = true;
      }, 500);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  get showBtnReschedule(): boolean {
    return !this.isRescheduling && this.model.situacao === 10 && this.model.id !== null;
  }

  onRescheduleEvent(): void {
    if (!this.model.id) {
      return;
    }

    this.dialogConfig.rescheduleConfirmation.show = true;
    this.dialogConfig.rescheduleConfirmation.color = 'green';
    this.dialogConfig.rescheduleConfirmation.message = this.$t('crm.activityCalendar.dialog.confirmReschedule')
      .toString();
    this.dialogConfig.rescheduleConfirmation.onChoice = (accept: boolean) => {
      this.dialogConfig.rescheduleConfirmation.show = false;
      if (accept) {
        this.$emit('reschedule', this.model.id);
      } else {
        this.isRescheduling = false;
      }
    };
  }

  onSelectClient(client: ClientModel | null): void {
    this.client = client;
    if (this.client) {
      this.clientItems = [this.client];
      this.model.cnpj = this.client.cnpjCpf;
      this.model.nome = this.client.nome;
      this.model.prospect = undefined;

      if (this.isProspectType) {
        const prospect = new ProspectModel();
        prospect.codProspect = this.client.codCliente as string;
        this.model.prospect = prospect;
      }

      this.model.representante = this.representativeOptions.find((x) => x.codigo === this.client?.codRepres);
    } else {
      this.model.cnpj = undefined;
      this.model.nome = '';
      this.model.prospect = undefined;
    }
  }

  onClientTypeChange(): void {
    this.client = null;
    this.clientItems = [];
  }

  async onSave(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        const model = ObjectHelper.mapObjectValues<ActivityEventModel>(this.model, null, {
          representante: (object) => (object === null ? null : pick(object, ['codigo', 'nome'])),
          origem: (object) => pick(object, ['id', 'descricao']),
          tipoHistorico: (object) => pick(object, ['id', 'nome']),
          atendente: (object) => pick(object, ['id', 'nome', 'email']),
          processo: (object) => (object === null ? null : pick(object, ['id'])),
        });

        if (this.dayToggle) {
          this.model.diaInteiro = 1;
        }

        model.timed = !!this.horarioInicial || !!this.horarioFinal;

        if (this.id) {
          // When editing, before save, remove already attached files from model
          model.anexos = model.anexos.filter((x) => !x.id).map(({ filename, path }) => ({ filename, path }));

          await this.activityService.update(model);
        } else {
          if (model.anexos?.length) {
            const clonedFileTask: Promise<ClonedUploadModel>[] = [];
            const clientId = this.isProspectType ? this.client?.codCliente : this.client?.cnpjCpf;

            // If in "duplicate mode", clone attachments  before save
            model.anexos
              .filter((file) => file.id)
              .forEach((file) => {
                clonedFileTask.push(
                  this.uploadService.clone(
                    file.path,
                    file.filename,
                    clientId as string,
                    this.uploadPlace,
                    this.uploadHash,
                  ),
                );
              });

            const cloneFileResult = await Promise.all(clonedFileTask);
            const clonedFiles = cloneFileResult.map(({ filename, path }) => ({ filename, path }));

            // New files that will be attached in the event
            const newFiles = model.anexos.filter((x) => !x.id).map(({ filename, path }) => ({ filename, path }));

            model.anexos = [...newFiles, ...clonedFiles];
          }

          const result = await this.activityService.create(model);
          model.id = result;
          this.id = result;

          if (this.isRescheduling) {
            model.idRetorno = this.cloneId;
            await this.activityService.update(model);
          }
        }

        if (this.eventClosed && this.model.situacao !== ActivityEventStatusEnum.Closed) {
          await this.activityService.close(model.id, !!model.flagEfetuouVenda);
        }

        this.$notify.success(this.$t('crm.activityCalendarEventForm.successfullySave'));

        this.$emit('complete', model);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  onSaveAndEmail(): void {
    this.model.flagEnviarEmail = 1;
    this.onSave();
  }

  onStartAtChange(): void {
    if (this.horarioInicial) {
      const time = CrmActivityCalendarEventForm.extractTime(this.horarioInicial);
      this.model.dataHoraInicio = DateHelper.setTime(this.model.dataHoraInicio, time[0], time[1]);
    }

    this.validateDate();
  }

  onStartAtTimeChange(): void {
    if (!this.model.dataHoraInicio) {
      return;
    }

    let time = [0, 0];
    if (this.horarioInicial) {
      time = CrmActivityCalendarEventForm.extractTime(this.horarioInicial);

      if (!this.horarioFinal) {
        this.model.dataHoraFim = DateHelper.setTime(this.model.dataHoraFim, time[0], (time[1] + 30));

        const hr = this.model.dataHoraFim.getHours();
        const min = this.model.dataHoraFim.getMinutes();

        this.horarioFinal = `${hr}:${min}`;
      }
    } else if (this.horarioFinal) {
      this.horarioFinal = null;
      this.model.dataHoraFim = DateHelper.setTime(this.model.dataHoraFim, 0, 0);
    }

    this.model.dataHoraInicio = DateHelper.setTime(this.model.dataHoraInicio, time[0], time[1]);

    this.validateDate();
  }

  onEndAtChange(): void {
    if (this.horarioFinal) {
      const time = CrmActivityCalendarEventForm.extractTime(this.horarioFinal);
      this.model.dataHoraFim = DateHelper.setTime(this.model.dataHoraFim, time[0], time[1]);
    }

    this.validateDate();
  }

  onEndAtTimeChange(): void {
    if (!this.model.dataHoraFim) {
      return;
    }

    let time = [0, 0];
    if (this.horarioFinal) {
      time = CrmActivityCalendarEventForm.extractTime(this.horarioFinal);

      if (!this.horarioInicial) {
        this.horarioInicial = this.horarioFinal;
        this.model.dataHoraInicio = DateHelper.setTime(this.model.dataHoraInicio, time[0], time[1]);
      }
    } else if (this.horarioInicial) {
      this.model.dataHoraInicio = DateHelper.setTime(this.model.dataHoraInicio, 0, 0);
    }

    this.model.dataHoraFim = DateHelper.setTime(this.model.dataHoraFim, time[0], time[1]);
    this.validateDate();
  }

  onDaySelected(): void {
    if (!this.dayToggle) {
      Object.assign(this.rules, {
        dataHoraInicio: [ValidationRules.required],
        dataHoraFim: [ValidationRules.required],
      });
      this.model.diaInteiro = 0;
      return;
    }

    this.model.diaInteiro = 1;
    this.model.dataHoraFim = this.model.dataHoraInicio;
    this.rules.dataHoraInicio = [];
    this.rules.dataHoraFim = [];
    this.horarioInicial = null;
    this.horarioFinal = null;
  }

  onGroupChange(model: GroupModel): void {
    this.loadHistoryTypes(model.id);
  }

  onAddAttachment(): void {
    this.uploadHash = CrmActivityCalendarEventForm.getHash();
    this.showUploader = true;
  }

  onCloseUploader(files: IUploadedFile[]): void {
    this.showUploader = false;
    this.model.anexos = [...this.model.anexos, ...files];
  }

  async onDeleteAttachment(file: ActivityEventAttachmentModel): Promise<void> {
    this.uploadService.delete(file.path);
    this.model.anexos = this.model.anexos.filter((x) => x.path !== file.path);

    if (file.id && this.id) {
      const loader = this.setBusyLoader();
      try {
        await this.activityService.deleteAttachment(this.id, file.id);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  onUploadFile(file: ActivityEventAttachmentModel): void {
    window.open(this.uploadService.getDownloadUrl(file.path), '_blank');
  }

  onDeleteUploadFiles(files: IUploadedFile[]): void {
    files.forEach((file: IUploadedFile) => {
      this.uploadService.delete(file.path);
    });
  }

  get isEditing(): boolean {
    return !!(this.id || this.cloneId);
  }

  get filteredAttendantOptions(): UserModel[] {
    return this.isEditing ? this.attendantOptions : this.attendantOptions.filter((a) => a.isAtivo);
  }

  get isProspectType(): boolean {
    return this.clientType === ClientTypeEnum.Prospect;
  }

  get disableDateFields(): boolean {
    return this.dayToggle;
  }

  get maxTimeStartAt(): string | null {
    if (toIsoDateString(this.model.dataHoraInicio) === toIsoDateString(this.model.dataHoraFim)) {
      return this.horarioFinal;
    }

    return null;
  }

  get minTimeEndAt(): string | null {
    if (toIsoDateString(this.model.dataHoraInicio) === toIsoDateString(this.model.dataHoraFim)) {
      return this.horarioInicial;
    }

    return null;
  }

  get showConsolidatedSale(): boolean {
    return !!this.model.tipoHistorico?.flagPassivoVenda;
  }

  get disableEventClosed(): boolean {
    return this.model.situacao === ActivityEventStatusEnum.Closed;
  }

  get isConvertedProspect(): boolean {
    return this.client?.situacao === ClientStatusEnum.Converted;
  }

  get dataHoraInicio(): Date | null {
    let parsedDataInicio = this.model.dataHoraInicio;

    if (parsedDataInicio !== null) {
      parsedDataInicio = dayjs(this.model.dataHoraInicio.toString()
        .substring(0, this.model.dataHoraInicio.toString().lastIndexOf(':') + 3)).toDate();
    }

    return parsedDataInicio;
  }

  set dataHoraInicio(value: Date | null) {
    if (value != null) {
      this.model.dataHoraInicio = value;
    }
  }

  get dataHoraFim(): Date | null {
    let parsedDataFim = this.model.dataHoraFim;

    if (parsedDataFim !== null) {
      parsedDataFim = dayjs(this.model.dataHoraFim.toString()
        .substring(0, this.model.dataHoraFim.toString().lastIndexOf(':') + 3)).toDate();
    }

    return parsedDataFim;
  }

  set dataHoraFim(value: Date | null) {
    if (value != null) {
      this.model.dataHoraFim = value;
    }
  }

  static extractTime(timeString: string): number[] {
    if (!timeString) {
      return [0, 0];
    }
    return timeString.split(':').map((x) => parseInt(x, 10));
  }

  static getHash(): string {
    return `${dayjs().format('HHmmssDD')}${padStart(random(1, 999).toString(), 3, '0')}`;
  }

  private validateDate(): void {
    if (dayjs(this.model.dataHoraFim).diff(this.model.dataHoraInicio, 'minute') < 0) {
      const startAt = this.model.dataHoraInicio;
      const { horarioInicial } = this;

      setTimeout(() => {
        this.horarioFinal = horarioInicial;
        this.model.dataHoraFim = startAt;
      });
    }
  }

  private async loadModel(): Promise<void> {
    if (!this.id && !this.cloneId) {
      return;
    }

    const result = await this.activityService.getEvent(this.id || this.cloneId);

    let pickList: IKeyValue<string[]> | null = null;

    if (this.cloneId) {
      pickList = {
        pick: [
          'dataHoraInicio',
          'dataHoraFim',
          'timed',
          'anexos',
          'atendente',
          'cnpj',
          'nome',
          'origem',
          'descricao',
          'representante',
          'tipoHistorico',
          'titulo',
          'prospect',
          'tipo',
          'processo',
        ],
      };
    }

    const mappedObject = ObjectHelper.mapObjectValues<ActivityEventModel>(result, pickList);

    this.model = plainToClass(ActivityEventModel, mappedObject);
  }

  private async loadOptions(): Promise<void> {
    await Promise.all([this.loadRepresentatives(), this.loadOrigins(), this.loadGroups(), this.loadAttendants()]);
  }

  private async loadRepresentatives(): Promise<void> {
    const clientId = this.isProspectType ? this.client?.codCliente : this.client?.cnpjCpf;
    this.representativeOptions = await this.representativeService.getRepresentatives(
      clientId,
      true,
    );
    this.representativeOptions = this.representativeOptions.filter((e) => {
      if (this.settings.flagApresentaRepresentantesInativosAgenda360
        || (!this.settings.flagApresentaRepresentantesInativosAgenda360 && e.situacao === 1)
        || (this.id && this.model.representante?.codigo === e.codigo)) {
        return true;
      }
      return false;
    });
  }

  private async loadOrigins(): Promise<void> {
    this.originOptions = sortBy(await this.activityService.getOrigins(), 'descricao');
  }

  private async loadHistoryTypes(groupId: number): Promise<void> {
    this.loadingHistoryTypeOptions = true;
    this.historyTypeOptions = sortBy(await this.activityService.getHistoryTypes(groupId), 'nome');
    this.loadingHistoryTypeOptions = false;
  }

  private async loadGroups(): Promise<void> {
    this.groupOptions = sortBy(await this.activityService.getGroups(), 'descricao');
  }

  private async loadAttendants(): Promise<void> {
    this.attendantOptions = await this.activityService.getAttendants(false, true);
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.calendarEventFormContainer,
      canCancel: false,
    });
  }
}
